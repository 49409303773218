import React from 'react';

import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom/client';

import App from './App';

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  history.replace(path);
}

root.render(
  <React.StrictMode>
    <App></App>
  </React.StrictMode>
);
