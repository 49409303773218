import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { AuthContext } from './contexts/auth-context';
import Router from './Router';
import { checkIsAuthed } from './utils/auth';

import './App.scss';

export default () => {
  // const [isAuthed, setIsAuthed] = useState(Cookies.get('CanAccess') === 'true');
  const [isAuthed, setIsAuthed] = useState(false);
  const value = { isAuthed, setIsAuthed };

  useEffect(() => {
    checkIsAuthed()
      .then((authed) => setIsAuthed(authed))
      .catch();
  }, [setIsAuthed]);

  return (
    <AuthContext.Provider value={value}>
      <Router />
    </AuthContext.Provider>
  );
};
