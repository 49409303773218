import * as jose from 'jose';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';

const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAm908xnGLav0lDmUvNZ3V
0QduwX2ONsNeXQwkw0HT40rcMCM0Ft7JboBxI7BiAohhx92xeFvsH/G7ltabhJlF
jhm46SlPVLQaiWPYo8Zuls0PwkdwAiQqO5xlOimP99ViJdJncBc+zxhHDoJMk5aD
GEV1jPbw07psjz9O0Z5JCF8ptd2vmpfvzdlyIwUsUlxhkkY4jhZa0KP1WfV63smH
/b+il4U9UCo/zS+FaE1lg4Ti1vcg5D93A/6jZYbcBjPDgN4VTlYfDERG+unWFKHU
MFCSbeBs6SjCmlTzZLYelYxMBimWAeeMBQtTsLCMjAidxWbjn72ptaiQMXVGMxR5
38jwZ76vr8mR8eqyW2aMaO3MDa3fMwWmuqpdJyfWnzHyoWPc1K1n3jfNtvRpgUJN
/yrZBBGSDutq4KMnCWeOQ+KX5zGXqnnA4HBCT042CQwiwLfVtdoNdLzfxDXJquq0
L9Jo2ORvdGJLRm4MXgQYJ7akBdlmpiGD8Sy2Ox2Lk2U2jolY0VRncgUb+wOnI8Pj
TuWhL7rLR6Q0PgVq5X8P7IrjMIDMFtvw9mm5B20cbE1i7nFZoeeqktJaW+P/cCj2
r+XykJ4gMmWjZGWMob+2oxrSQixk/1cdScScHIoJOFLP44MezHoAnf1kQ42mu+Hg
v8eYrmyq9s0xW7vCIz5cJpcCAwEAAQ==
-----END PUBLIC KEY-----`;

export const checkIsAuthed = async () => {
  const jwt = Cookies.get('Token');
  const key = await jose.importSPKI(publicKey, 'RS256');

  /* eslint-disable @typescript-eslint/no-empty-function */
  try {
    const result = await jose.jwtVerify(jwt || '', key, { issuer: 'mcneil.wedding' }).catch(() => undefined);
    return (
      (result?.payload?.iss === 'mcneil.wedding' &&
        result?.payload?.exp &&
        DateTime.fromSeconds(result.payload.exp) > DateTime.now()) ||
      process.env.BYPASS_AUTH_CHECK === 'true'
    );
  } catch (e) {}

  return process.env.BYPASS_AUTH_CHECK === 'true';
};
