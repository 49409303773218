import React from 'react';

import './Hotel.scss';

type Props = {
  name: string;
  address: string;
  distance: string;
  rate: number;
};

export default ({ name, address, distance, rate }: Props) => {
  return (
    <div className="hotel">
      <div className="hotel__name">{name}</div>
      <div className="hotel__address">{address}</div>
      <div className="hotel__distance">{distance} from venue</div>
      <div className="hotel__rate">${rate} estimated nightly rate</div>
    </div>
  );
};
