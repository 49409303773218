import React, { useCallback, useContext, useState } from 'react';

import { Button, Input, InputLabel, TextField, Typography } from '@mui/material';
import * as axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../contexts/auth-context';
import { checkIsAuthed } from '../utils/auth';

import './Verify.scss';

export default () => {
  const navigate = useNavigate();
  const { setIsAuthed } = useContext(AuthContext);

  const [token, setToken] = useState('');

  const submitToken = useCallback(
    (tkn: string) => {
      axios.default
        .get(`https://api.mcneil.wedding/token?token=${tkn}`, {
          withCredentials: true,
        })
        .then(() => {
          checkIsAuthed().then((authed) => {
            setIsAuthed(authed);
            navigate('/home');
          });
        })
        .catch();
    },
    [axios.default]
  );

  return (
    <div className="verify">
      {/* <InputLabel>
        Enter your authenticator code
        <Input onChange={(e) => setToken(e.target.value)}></Input>
      </InputLabel> */}
      <TextField label="Authenticator Token" variant="outlined" onChange={(e) => setToken(e.target.value)} />
      <Button className="verify__submit" variant="contained" onClick={() => submitToken(token)}>
        Submit
      </Button>

      <div className="verify__info">
        <Typography variant="h5" className="info__header">
          What is this?
        </Typography>
        <Typography variant="body1" gutterBottom>
          We want to provide a website for more detailed wedding information to our friends and family, but we are
          concerned about maintaining our privacy. Balancing these concerns, we have implemented a method of
          authentication to the website that may be a bit different than you are used to. Instead of using a traditional
          password, which has multiple downsides, we are using a time-based one-time password (TOTP) which you may be
          more familiar with seeing used as a two-factor authentication method. This is a rolling code with a thirty
          second window that is generated by an app on your device that is seeded by a secret key that only your app and
          the server know.
        </Typography>

        <Typography variant="h5" className="info__header">
          How do I get this code?
        </Typography>
        <Typography variant="body1" gutterBottom>
          On our Save The Dates/Invitations we included a QR code that you can scan with a mobile authenticator app, and
          from that point the app will generate a rolling six digit code for you to enter.
        </Typography>
        <div className="info__totp"></div>

        <Typography variant="h5" className="info__header">
          What app do I need?
        </Typography>
        <Typography variant="body1" gutterBottom>
          There are many options, but we would suggest the Microsoft Authenticator app which is available on both
          Android and iPhone
        </Typography>
        <div className="info__apps">
          <a
            className="apps__android"
            href="https://play.google.com/store/apps/details?id=com.azure.authenticator&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={{ borderRadius: '13px' }}
            />
          </a>
          <a
            className="apps__iphone"
            href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              borderRadius: '13px',
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1432944000&h=39686e6a537b2c44ff7ce60f6287e68f"
              alt="Download on the App Store"
              style={{ borderRadius: '13px' }}
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};
