import React from 'react';

import { Link } from '@mui/material';

import './Registry.scss';

export default () => {
  return (
    <div className="registry">
      <div className="registry__heading">Registry</div>
      <Link href="https://www.amazon.com/wedding/gina-correa-james-mcneil--october-2023/registry/VIA55L1J9UFX">
        Our Amazon Registry
      </Link>
      <Link href="https://www.honeyfund.com/site/mcneil-wedding#module_registry_items">Our Honeyfund Registry</Link>
    </div>
  );
};
