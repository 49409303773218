import React, { useContext, useEffect, useMemo, useState } from 'react';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Body from './Body';
import { AuthContext } from './contexts/auth-context';
import Accomodations from './pages/Accomodations';
import Home from './pages/Home';
import Registry from './pages/Registry';
import Verify from './pages/Verify';

export default () => {
  const { isAuthed } = useContext(AuthContext);

  const pathName = window.location.href.slice(window.location.href.lastIndexOf('/'));

  const [redirectUrl, setRedirectUrl] = useState('/home');

  useEffect(() => {
    if (!pathName.endsWith('verify')) setRedirectUrl(pathName);
  }, [pathName]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Body />}>
          <Route path="home" element={isAuthed ? <Home /> : <Navigate to="/verify"></Navigate>} />
          <Route path="accomodations" element={isAuthed ? <Accomodations /> : <Navigate to="/verify"></Navigate>} />
          <Route path="registry" element={isAuthed ? <Registry /> : <Navigate to="/verify"></Navigate>} />
          <Route path="verify" element={isAuthed ? <Navigate to={redirectUrl}></Navigate> : <Verify />}></Route>
          <Route path="*" element={<Navigate to="/home"></Navigate>}></Route>
          <Route path="" element={<Navigate to="/home"></Navigate>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
