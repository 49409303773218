import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material';
import { DateTime } from 'luxon';

import { mapDurationToText } from '../utils/duration';

import './Countdown.scss';

export default ({ date }: { date: string }) => {
  const theme = useTheme();

  const [timeLeft, setTimeLeft] = useState(
    DateTime.fromISO(date).diffNow([/*'years', */ 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'])
  );

  useEffect(() => {
    const interval = setInterval(
      () =>
        setTimeLeft(
          DateTime.fromISO(date).diffNow([
            /*'years', */ 'months',
            'days',
            'hours',
            'minutes',
            'seconds',
            'milliseconds',
          ])
        ),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="countdown" style={{ backgroundColor: theme.palette.primary.main }}>
      <div className="countdown__heading">Countdown to Wedding Ceremony</div>
      <div className="countdown__times">
        {mapDurationToText(timeLeft).map(({ value, unit }) => (
          <div key={unit} className={`times__time times__time--${value.length ? 'visible' : 'hidden'}`}>
            <span className="time__value">{value}</span>
            <span className="time__unit">{unit}</span>
          </div>
        ))}
      </div>
      <div className="countdown__rsvp">Please RSVP By Mail</div>
      {/* <div className="countdown__registry-info">Registry information to come</div> */}
    </div>
  );
};
