import * as colors from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  // palette: {
  //   primary: {
  //     // main: colors.brown[400],
  //     main: colors.orange[900],
  //   },
  //   secondary: {
  //     main: colors.yellow.A700,
  //   },
  //   background: {
  //     // default: colors.brown[200],
  //     default: colors.orange[500],
  //   },
  // },

  // palette: {
  //   primary: {
  //     main: colors.purple[600],
  //     //main: colors.orange[900],
  //   },
  //   secondary: {
  //     main: colors.yellow.A700,
  //   },
  //   background: {
  //     default: colors.purple[100],
  //     // default: colors.orange[500],
  //   },
  // },

  palette: {
    primary: {
      main: '#BCBF32',
      light: '#dadd6c',
      //main: colors.orange[900],
    },
    secondary: {
      main: '#D27F23',
    },
    background: {
      default: '#F6F0D8',
      // default: colors.orange[500],
    },
  },
});
