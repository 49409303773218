import React, { useState } from 'react';

import './Home.scss';

import { useTheme } from '@mui/material';
import { DateTime } from 'luxon';

import Countdown from '../components/Countdown';
import { weddingDate } from '../utils/constants';

export default () => {
  const theme = useTheme();
  const [date] = useState(DateTime.fromISO(weddingDate).toFormat('LL.dd.yyyy'));

  return (
    <div style={{ height: '100%', backgroundColor: theme.palette.primary.main }}>
      <div className="names">
        {/* <img className="names__header"></img> */}
        <svg viewBox="0 0 500 150" className="names__header">
          <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
          <text width="500" fill={theme.palette.primary.light}>
            <textPath xlinkHref="#curve">THE MCNEIL WEDDING</textPath>
          </text>
        </svg>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className="names__header"
          viewBox="0 0 238.77864 48.338264"
          fill={theme.palette.primary.light}
        >
          <g id="layer1" transform="translate(-44.452213,-99.894204)">
            <text
              xmlSpace="preserve"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontSize: '22.225px',
                lineHeight: 1.25,
                fontFamily: 'Segoe UI',
                strokeWidth: 0.264583,
              }}
              id="text2480"
            >
              <textPath xlinkHref="#path18492" id="textPath18576">
                THE MCNEIL WEDDING
              </textPath>
            </text>
            <defs>
              <path
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: '0.377507px',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeOpacity: 1,
                }}
                d="m 50.068137,147.56198 c 36.666585,-15.44429 73.332773,-30.88841 129.479303,-30.88805 36.14654,3.7e-4 71.77185,15.44418 107.39727,30.88805"
                id="path18492"
              />
            </defs>
          </g>
        </svg> */}

        <div className="names__name">Gina</div>
        <div className="names__name">&</div>
        <div className="names__name">James</div>
        <div className="names__overlay"></div>
        <div className="names__date">{date}</div>
        <div className="names__location">Drumore, PA</div>
        {/* <svg viewBox="0 0 210 297" className="names__std" fill={theme.palette.primary.light}>
          <text xmlSpace="preserve" id="text38712">
            <textPath xlinkHref="#path49120" id="textPath49204">
              <tspan
                id="tspan38710"
                style={{
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontFamily: 'Segoe UI',
                  strokeWidth: 0.264583,
                  fontSize: '21px',
                }}
              >
                SAVE THE DATE
              </tspan>
            </textPath>
          </text>
          <defs>
            <path
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: '0.264583px',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeOpacity: 1,
              }}
              d="m 21.444043,107.93501 c 25.018369,8.33946 50.036419,16.67881 75.054639,16.61907 25.018218,-0.0597 50.035768,-8.51804 75.053658,-16.97647"
              id="path49120"
            />
          </defs>
        </svg> */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          // viewBox="0 0 151 40"
          viewBox="0 0 165 40"
          className="names__std"
          fill={theme.palette.primary.light}
        >
          <text xmlSpace="preserve" id="text38712">
            <textPath xlinkHref="#path49120" id="textPath49204">
              <tspan
                id="tspan38710"
                style={{
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontFamily: 'Segoe UI',
                  strokeWidth: 0.264583,
                  fontSize: '21px',
                }}
              >
                SAVE THE DATE
              </tspan>
            </textPath>
          </text>
          <defs>
            <path
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: '0.264583px',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeOpacity: 1,
              }}
              d="m 0,15 c 25.018369,8.33946 50.036419,16.67881 85.054639,16.61907 25.018218,-0.0597 50.035768,-8.51804 85.053658,-16.97647"
              id="path49120"
            />
          </defs>
        </svg> */}

        <div className="names__std" style={{ color: theme.palette.primary.light }}>
          SAVE THE DATE
        </div>
        {/* <svg viewBox="0 0 500 150" className="names__std">
          <path id="curve" d="M 360 -100 c -4 6.1 -65.5 96.8 -178.6 95.6 c -111.3 -1.2 -170.8 -90.3 -175.1 -97" />
          <text width="500" fill={theme.palette.primary.light}>
            <textPath xlinkHref="#curve">SAVE THE DATE</textPath>
          </text>
        </svg> */}
      </div>
      <Countdown date={weddingDate}></Countdown>
    </div>
  );
};
