import React from 'react';

import Hotel from '../components/Hotel';

import './Accomodations.scss';

const hotels: {
  name: string;
  address: string;
  distance: string;
  rate: number;
}[] = [
  {
    name: 'DoubleTree Resort by Hilton Hotel Lancaster',
    address: '2400 Willow Street Pike, Lancaster, PA 17602-4849',
    distance: '30 minutes',
    rate: 186,
  },
  {
    name: 'Pheasant Run Farm Bed and Breakfast',
    address: '200 Marticville Rd, Lancaster, PA 17603-9653',
    distance: '30 minutes',
    rate: 169,
  },
  {
    name: 'Holiday Inn Lancaster, an IHG Hotel',
    address: '26 E Chestnut St, Lancaster, PA 17602',
    distance: '35 minutes',
    rate: 162,
  },
  {
    name: 'Holiday Inn Express & Suites Lancaster East - Strasburg, an IHG Hotel',
    address: '1900 Historic Dr, Strasburg, PA 17579-1471',
    distance: '35 minutes',
    rate: 125,
  },
  {
    name: 'Residence Inn by Marriott Lancaster',
    address: '1450 Harrisburg Pike, Lancaster, PA 17601-2085',
    distance: '42 minutes',
    rate: 144,
  },
  {
    name: 'Best Western Chesapeake Bay North Inn',
    address: '39 Elwoods Rd, North East, MD 21901',
    distance: '37 minutes',
    rate: 113,
  },
  {
    name: 'Hawthorn Suites by Wyndham Lancaster',
    address: '2045 E Lincoln Hwy, Lancaster, PA 17602',
    distance: '35 minutes',
    rate: 134,
  },
];

export default () => {
  return (
    <div className="accomodations">
      <div className="accomodations__heading">Accomodations</div>
      <div className="accomodations__description">
        For anyone choosing to stay at a hotel close to the venue, you will find a few options below. We will be also be
        able to accomodate several travelling family members at our home.
      </div>
      <div className="accomodations__list">
        <div className="list__hotel">
          {hotels.map((hotel) => (
            <Hotel key={hotel.name} {...hotel}></Hotel>
          ))}
        </div>
      </div>
    </div>
  );
};
