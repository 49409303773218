import { Duration } from 'luxon';

export const mapDurationToText = (duration: Duration) => {
  // const years = duration.get('years');
  const months = duration.get('months');
  const days = duration.get('days');
  const hours = duration.get('hours');
  // const minutes = duration.get('minutes');
  // const seconds = duration.get('seconds');

  return [
    // {
    //   value: years ? `${years}` : '',
    //   unit: `year${years === 1 ? '' : 's'}`,
    // },
    // {
    //   value: months ? `${months}` : years > 0 ? '0' : '',
    //   unit: `month${months === 1 ? '' : 's'}`,
    // },
    {
      value: months ? `${months}` : '',
      unit: `month${months === 1 ? '' : 's'}`,
    },
    {
      value: days ? `${days}` : months > 0 ? '0' : '',
      unit: `day${days === 1 ? '' : 's'}`,
    },
    {
      value: hours ? `${hours}` : days > 0 ? '0' : '',
      unit: `hour${hours === 1 ? '' : 's'}`,
    },
    // {
    //   value: minutes ? `${minutes}` : hours > 0 ? '0' : '',
    //   unit: `minute${minutes === 1 ? '' : 's'}`,
    // },
    // {
    //   value: seconds ? `${seconds}` : minutes > 0 ? '0' : '',
    //   unit: `second${seconds === 1 ? '' : 's'}`,
    // },
  ];
};
