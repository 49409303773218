import React, { useContext, useEffect } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Container, ThemeProvider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as colors from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from './contexts/auth-context';
import Home from './pages/Home';
import { theme } from './theme';
import { checkIsAuthed } from './utils/auth';

import './Body.scss';

export default () => {
  const location = useLocation();
  const { setIsAuthed } = useContext(AuthContext);

  useEffect(() => {
    checkIsAuthed()
      .then((authed) => setIsAuthed(authed))
      .catch();
  }, [location, setIsAuthed]);

  const pages = ['home', 'accomodations', 'registry'];

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Home
            </Typography> */}
            {/* <Button color="inherit">Login</Button> */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button key={page} onClick={() => navigate(page)} sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <div style={{ flexGrow: 1, backgroundColor: theme.palette.background.default }}>
          {/* <Container maxWidth="sm">

        </Container> */}
          {/* <Home></Home> */}
          <Outlet></Outlet>
        </div>
      </Box>
      {/* <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: colors.brown[400] }}></Box> */}
    </ThemeProvider>
  );
};
